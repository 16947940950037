/*==============================
#0.8 tp-about-area
===============================*/
.about-style,
.about-style-1,
.about-style-2,
.about-style-3 {
    padding: 100px 0;
    border-bottom: 1px solid rgba(102, 102, 102, .2);
}

.about-style .about-content p,
.about-style-1 .about-content p,
.about-style-2 .about-content p {
    margin-bottom: 40px;
    color: #898888;
}

.about-style .about-content,
.about-style-1 .about-content,
.about-style-2 .about-content,
.about-style-3 .about-content {
    padding-left: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.about-img img {
    padding: 15px;
    border: 1px solid #ffc7b1;
}

.about-style .about-content h2,
.about-style-1 .about-content h2,
.about-style-3 .about-content h2 {
    color: #1d375c;
}

.section-title span,
.section-title-1 span {
    font-size: 16px;
    color: #f67d4a;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.section-title h2,
.section-title-1 h2 {
    font-size: 36px;
    font-weight: 700;
    color: #444;
    padding-bottom: 20px;
    margin-bottom: 50px;
    position: relative;
    margin-top: 15px;
}


.section-title h2:before,
.section-title-1 h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 3px;
    background: #f67d4a;
    border-radius: 20px;
}

.section-title-1 h2 {
    margin-bottom: 60px;
}

.section-title-1 h2:before {
    left: 46%;

}

.about-style-1 .about-img {
    text-align: right;
}

.col-m {
    padding-right: 0;
}

.about-style,
.about-style-1,
.about-style-2 {
    padding: 100px 0;
}

.about-style-2 .row {
    display: flex;
}

.about-style .about-content p,
.about-style-1 .about-content p {
    margin-bottom: 40px;
    color: #333;
}

.about-style-2 .about-content {
    padding-left: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.about-style-2 .section-title h2:before {
    left: 0;
    transform: unset;
}

.about-img img {
    padding: 15px;
    border: 4px solid rgba(102, 102, 102, .2);
    width: 100%;
}

.about-style .about-content h2,
.about-style-1 .about-content h2 {
    color: #1d375c;
}

.section-title span,
.section-title-1 span {
    font-size: 16px;
    color: #f67d4a;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
    display: block;
}

.section-title h2,
.section-title-1 h2 {
    font-size: 36px;
    font-weight: 700;
    color: #c3bfbf;
    padding-bottom: 20px;
    margin-bottom: 50px;
    position: relative;
    margin-top: 15px;
    display: inline-block;
}

.section-title h2:before,
.section-title-1 h2:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 80px;
    height: 3px;
    background: #f67d4a;
    border-radius: 20px;
    transform: translateX(-50%);
}

.section-title-1 h2 {
    margin-bottom: 60px;
}

.section-title-1 h2:before {
    left: 46%;

}

.about-style-2 .about-img {
    text-align: right;
}

.col-m {
    padding-right: 0;
}

.about-style-1 .about-img,
.about-style-2 .about-img {
    position: relative;
    text-align: left;
    margin-right: 40px;
}

.about-style-1 .about-img:before,
.about-style-2 .about-img:before {
    position: absolute;
    left: 16px;
    top: 17px;
    width: 94%;
    height: 94%;
    background: rgba(0, 0, 0, .40);
    content: "";
}

.about-style-2 .video-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 0;
    background: none;
}
.about-style-2 .video-btn:focus{
    outline: none;
}

.about-style-2 .video-btn .flaticon-play-button:before {
    border: 10px solid rgba(246, 125, 74, .4);
    border-radius: 50%;
    background: rgba(246, 125, 74, .7);
    -webkit-animation: spineer 2s infinite;
    animation: spineer 2s infinite;
}

@-webkit-keyframes spineer {
    0% {
        -webkit-box-shadow: 0 0 0 0 #f67d4a;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(250, 92, 93, .0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 92, 93, .0);
    }
}

@keyframes spineer {
    0% {
        -webkit-box-shadow: 0 0 0 0 #f67d4a;
        box-shadow: 0 0 0 0 #f67d4a;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(250, 92, 93, .0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 92, 93, .0);
        box-shadow: 0 0 0 0 rgba(250, 92, 93, .0);
    }
}

.signature {
    margin-top: 20px;
}


@media(max-width: 991px) {
    .about-style-2 .row {
        display: unset;
    }

    .about-content {
        margin-top: 30px;
    }

    .about-style-1 .about-img:before,
    .about-style-2 .about-img:before {
        height: 95%;
    }

    .about-style-1 .about-img,
    .about-style-2 .about-img {
        margin-right: 0px;
    }

    .about-style,
    .about-style-1,
    .about-style-2 {
        padding: 50px 0;
    }
}

@media(max-width:1200px){
    .section-title h2, .section-title-1 h2 {
        font-size: 30px;
    }
}

@media(max-width:767px) {

    .section-title h2,
    .section-title-1 h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

@media(max-width:375px) {

    .about-style-1 .about-img:before,
    .about-style-2 .about-img:before {
        width: 87%;
        height: 88%;
    }
}
