/*----------------------------------------------
#9.0 event-details-page
----------------------------------------------*/

.tp-event-details-text {
  margin-top: 50px;
}

.tp-event-details-text h2 {
  margin-bottom: 30px;
}

.tp-event-details-wrap {
  margin-top: 40px;
}

.tp-event-details-tab .nav {
  justify-content: space-between;
  display: flex;
}

.tp-event-details-tab .nav-tabs>li {
  float: left;
  margin-bottom: -1px;
  width: 100%;
}

.tp-event-details-wrap {
  border: 1px solid #ebebeb;
}

.tp-event-details-tab .nav li a {
  display: block;
  text-align: center;
  color: #2d4277;
  transition: all .5s;
  font-weight: 700;
  margin-right: 1px;
  border: none;
  border-radius: 0px;
  background: #f6f6f6;
  border-bottom: none;
  border-top: 4px solid transparent;
  line-height: 20px;
  height: 50px;
}

.tp-event-details-tab .nav li:last-child a {
  margin-right: -1px;
}

.tp-event-details-tab .nav li.active a,
.tp-event-details-tab .nav li:hover a {
  background: #fff;
  outline: none;
  border: none;
  color: #2d4277;
  border-top: 4px solid #f67d4a;
}

.tp-event-details-content {
  padding: 30px;
}

#Schedule ul {
  list-style: none;
  margin-top: 30px;
}

#Schedule ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}

#Schedule ul li:before {
  content: "";
  background: #f67d4a;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0;
  top: 6px;
}

#Map .contact-map iframe {
  width: 100%;
  height: 500px;
}

.event-contact .tp-donations-details {
  border: none;
  padding: 0;
}

.event-contact .tp-donations-details .theme-btn {
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: none;
  margin-top: 20px;
}

@media(max-width: 590px) {
  .tp-event-details-tab .nav li a {
      font-size: 12px;
  }
}

@media(max-width: 400px) {
  .tp-event-details-tab .nav {
      flex-wrap: wrap;
  }

  .tp-event-details-tab .nav li a {
      margin-bottom: 15px;
  }
}

.progress-section {
  padding-top: 35px;
}

.progress {
  height: 5px!important;
  overflow: unset;
}

.progress .progress-bar {
  border-radius: 15px;
  box-shadow: none;
  position: relative;
  animation: animate-positive 4s;
  width: 65%;
  background: #f67d4a;
  height: 5px;
}

.progress .progress-value {
  text-align: center;
  right: 0;
  top: -30px;
  position: absolute;
  color: #c3bfbf;
  font-size: 20px;
}


.tp-case-text-top ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.tp-case-text-top ul li {
  font-size: 16px;
  color: #898888;
  margin-top: 10px;
}

.tp-case-text-top ul li span {
  font-weight: 700;
}

.case-bb-text ul {
  display: unset;
}

.sub-btn {
  margin-top: 30px;
}
