
/*=============================================
#1.3 tp-event-area
===============================================*/



.event-section {
  padding-bottom: 70px;
}

.event-section .event-grids {
  margin: 0 -1px;
}

.event-section .event-grids .grid {
  width: calc(33.33% - 2px);
  float: left;
  margin: 0 1px;
}

@media (max-width: 991px) {
  .event-section .event-grids .grid {
      width: calc(50% - 2px);
      margin-bottom: 30px;
  }

  .event-section .event-grids .grid img {
      width: 100%;
      min-height: 280px;
      object-fit: cover;
  }
}

@media (max-width: 650px) {
  .event-section .event-grids .grid {
      width: calc(100% - 2px);
  }
}

.event-section .grid .details {
  background-color: #262525;
  padding: 30px 35px;
}

@media (max-width: 1199px) {
  .event-section .grid .details {
      padding: 30px 25px;
  }
}

@media (max-width: 767px) {
  .event-section .grid .details {
      padding: 25px 20px;
  }
}

.event-section .grid .details h3 {
  font-size: 22px;
  line-height: 1.5em;
  font-weight: 600;
  margin: 1em 0 0;
}

@media (max-width: 1200px) {
  .event-section .grid .details h3 {
      font-size: 20px;
  }
}

@media (max-width: 991px) {
  .event-section .grid .details h3 {
      font-size: 18px;
      font-size: 1.2rem;
  }
}

.event-section .grid .details h3 a {
  color: #c3bfbf;
}

.event-section .grid .details h3 a:hover {
  color: #f67d4a;
}

.event-section .entry-meta {
  list-style: none;
  overflow: hidden;
}

.event-section .entry-meta li {
  float: left;
}

.event-section .entry-meta>li+li {
  margin-left: 25px;
}

.event-section .entry-meta li a {
  font-size: 14px;
  font-size: 0.93333rem;
  color: #999;
}

@media (max-width: 1199px) {
  .event-section .entry-meta li a {
      font-size: 13px;
      font-size: 0.86667rem;
  }
}

.event-section .entry-meta i {
  display: inline-block;
  padding-right: 3px;
}

.event-section .entry-meta li a:hover {
  color: #f67d4a;
}
