/*.===============================
#1.0 tp-counter-area
===============================*/
.tp-counter-area,
.tp-counter-style-2 {
    padding: 100px 0;
    background: url(../../images/counter/img-1.png) no-repeat center center / cover;
    position: relative;
}

.tp-counter-area:before,
.tp-counter-style-2:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .79);
}

.tp-counter-area .tp-counter-grids .grid,
.tp-counter-style-2 .tp-counter-grids .grid {
    width: 24%;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
}

.tp-counter-area .tp-counter-grids .grid:before,
.tp-counter-style-2 .tp-counter-grids .grid:before {
    content: "";
    position: absolute;
    top: 15px;
    right: 0;
    width: 1px;
    height: 70px;
    background: #636363;
}

.tp-counter-area .tp-counter-grids .grid:last-child:before,
.tp-counter-style-2 .tp-counter-grids .grid:last-child:before {
    display: none;
}

.tp-counter-area .tp-counter-grids .grid p,
.tp-counter-style-2 .tp-counter-grids .grid p {
    font-size: 18px;
    font-weight: normal;
    color: #999;
    padding-top: 5px;
    margin-bottom: 0;
}

.tp-counter-area .tp-counter-grids,
.tp-counter-style-2 .tp-counter-grids {
    overflow: hidden;
    position: relative;
}

.tp-counter-area .odometer.odometer-auto-theme,
.tp-counter-area .odometer.odometer-theme-default,
.tp-counter-style-2 .odometer.odometer-auto-theme,
.tp-counter-style-2 .odometer.odometer-theme-default {
    line-height: 0.8em;
}

.tp-counter-area .tp-counter-grids .grid h2,
.tp-counter-style-2 .tp-counter-grids .grid h2 {
    font-size: 60px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    line-height: 50px;
    margin-top: 8px;
    padding-bottom: 15px;
}

@media(max-width: 1200px) {

    .tp-counter-area .tp-counter-grids .grid,
    .tp-counter-style-2 .tp-counter-grids .grid {
        width: 23%;
    }
}



@media(max-width: 992px) {

    .tp-counter-area .tp-counter-grids .grid h2,
    .tp-counter-style-2 .tp-counter-grids .grid h2 {
        font-size: 35px;
    }

    .tp-counter-area,
    .tp-counter-style-2 {
        padding: 50px 0;
    }
}

@media (max-width: 590px) {

    .tp-counter-area .tp-counter-grids .grid,
    .tp-counter-style-2 .tp-counter-grids .grid {
        width: 45%;
    }

    .tp-counter-area .tp-counter-grids .grid:before,
    .tp-counter-style-2 .tp-counter-grids .grid:before {
        display: none;
    }
}

.tp-counter-area.v2 {
    background: #101010;
    border-top: 1px solid #202120;
}

.tp-counter-area.v2:before {
   display: none;
}