/*================================================
#1.2 tp-team-area
==================================================*/

.team-section {
  background: #1e1e1e;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .team-section {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .team-section {
    padding-bottom: 60px;
  }
}

.team-section .team-grids {
  margin: 0 -15px;
}

@media (max-width: 767px) {
  .team-section .team-grids {
    margin: 0 -7.5px;
  }
}

.team-section .team-grids .grid {
  width: calc(33.33% - 30px);
  float: left;
  margin: 0 15px 30px;
}

@media (max-width: 991px) {
  .team-section .team-grids .grid {
    width: calc(50% - 30px);
  }
}

@media (max-width: 767px) {
  .team-section .team-grids .grid {
    width: calc(50% - 15px);
    margin: 0 7.5px 30px;
  }
}

@media (max-width: 550px) {
  .team-section .team-grids .grid {
    width: calc(100% - 15px);
    float: none;
  }
}

.team-section .img-holder {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
  position: relative;
}

.team-section .img-holder img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transform: scale(1);
    transition: all .3s; 
}
.team-section .grid:hover .img-holder img{
  transform: scale(1.2);
}
.team-section .details {
  background-color: #101010;
  text-align: center;
  padding: 65px 15px 20px;
  margin-top: -35px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.team-section .details h3 {
  font-size: 22px;
  margin: 0 0 0.2em;
}
.team-section .details h3 a{
  color: #ddd;
}
.team-section .details h3 a:hover{
  color: #f67d4a;
}

@media (max-width: 991px) {
  .team-section .details h3 {
    font-size: 18px;
  }
}

.team-section .details p {
  margin: 0;
}

@media (max-width: 991px) {
  .team-section .details p {
    font-size: 14px;
  }
}
