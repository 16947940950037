
/*=========================================
#5.0 donation
==========================================*/

.tp-donations-amount,
.tp-donations-details,
.tp-doanation-payment {
    padding: 30px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 9.8px 0.2px rgba(85, 85, 85, 0.07);
}

.tp-donations-amount h2,
.tp-donations-details h2,
.tp-doanation-payment h2 {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 30px;
    margin-top: 0;
}

.tp-donations-amount input,
.tp-donations-details input,
.tp-donations-details textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 0;
    border: 1px solid #2e2c2c;
    padding-left: 15px;
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #525252;
    border-radius: 5px;
    font-size: 18px;
}

.tp-donations-details textarea {
    height: 135px;
}

.tp-donations-amount input:focus,
.tp-donations-details input:focus,
.tp-donations-details textarea:focus {
    border-color: #f67d4a;
}

.payment-name ul li input {
    margin-right: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.tp-payment-select ul {
    list-style: none;
}

.payment-name ul {
    display: flex;
    margin-top: 30px;
}

.tp-payment-area form {
    overflow: unset !important;
}

.payment-name ul li {
    width: 100px;
    height: 60px;
    text-align: center;
    line-height: 60px;
}

.payment-name ul {
    list-style: none;
}

.payment-name ul li input {
    margin-right: 0;
}

.payment-name ul li {
    margin-right: 15px;
}

.tp-payment-area h2 {
    padding-bottom: 40px;
    margin-bottom: 0;
}

.tp-payment-select ul {
    display: flex;
}

.tp-payment-select ul li {
    margin-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #5B5B5B;
}

.tp-payment-select label {
    font-size: 18px;
    font-weight: 600;
}

.tp-payment-area h2 {
    font-size: 20px;
    color: #878787;
    font-weight: 700;
}

.tp-payment-area h2 span {
    font-size: 30px;
    color: #ff493c;
}

.tp-payment-area {
    width: 100%;
}

.payment-name ul li input {
    margin-right: 0;
    position: absolute;
    z-index: -1;
    font-size: 18px
}

.payment-name ul li input:checked~label {
    background: #cae8c8;
}

.payment-name label {
    width: 100%;
    border: 1px solid transparent;
    cursor: pointer;
}

.payment-name .visa label {
    border: 1px solid #0057A0;
}

.payment-name .mas label {
    border: 1px solid #CC0000;
}

.payment-name .ski label {
    border: 1px solid #691A5F;
}

.payment-name .pay label {
    border: 1px solid #019CDE;
}

.tp-payment-option {
    padding: 0 15px;
}

.payment-name.active {
    display: block;
}

.tp-payment-option.active .payment-name {
    display: none !important;
}

.tp-payment-area .form-style input {
  width: 100%;
  margin-bottom: 15px;
  padding-left: 20px;
  height: 40px;
  background: #202120;
  border-radius: 2px;
  border: none;
  border: 1px solid #2e2c2c;
}

.tp-payment-area .form-style input:focus {
    border: 1px solid #f67d4a;
}

.tp-payment-area .contact-form {
    margin-top: 40px;
}

#Donations .theme-btn {
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: none;
    padding-right: 27px;
}

#Donations .submit-area {
    text-align: center;
}