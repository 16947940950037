/*--------------------------------------------------------------
#0.6 news-letter-part
--------------------------------------------------------------*/
.news-letter-section {
  text-align: center;
  position: relative;
  z-index: 1;
}

.news-letter-section:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  content: "";
  background: #1e1e1e;
  z-index: -1;
}

.news-letter-wrap {
  max-width: 970px;
  margin: auto;
  box-shadow: 0px 4px 9.5px 0.5px rgb(53 53 53 / 10%);
  padding: 60px;
  background: #262525;
  border-radius: 10px;
}

@media (max-width: 991px) {
  .news-letter-section {
      padding: 0;
  }
}

.news-letter-section h3 {
  font-size: 45px;
  line-height: 1.3em;
  color: #fff;
  font-weight: 600;
  margin: 0 auto 0.5em;
}

.news-letter-section p {
  margin: 0 0 2em;
}

@media (max-width: 1024px) {
  .news-letter-section h3 {
      font-size: 28px;
      line-height: 34px;
  }
}

@media (max-width: 991px) {
  .news-letter-section h3 {
      font-size: 25px;
      font-size: 1.5625rem;
  }
}

@media (max-width: 767px) {
  .news-letter-section h3 {
      font-size: 18px;
  }

  .news-letter-wrap {
      padding: 30px;
  }
}

.news-letter-section form,
.news-letter-section-2 form {
  max-width: 425px;
  margin: 0 auto;
}

.news-letter-section form>div {
  position: relative;
}

.news-letter-section form>div input {
  height: 55px;
  border: 0;
  outline: 0;
  border-radius: 55px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 15px 15px 15px 25px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

@media (max-width: 991px) {
  .news-letter-section form>div input {
      height: 50px;
  }
}

.news-letter-section form>div button {
  background: #f67d4a;
  width: 150px;
  height: 45px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  border: 0;
  outline: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 45px;
  line-height: 45px;
}

@media (max-width: 991px) {
  .news-letter-section form>div button {
      width: 120px;
      height: 40px;
      line-height: 40px;
  }
}


.news-letter-section.s2 {
  text-align: center;
  position: relative;
  z-index: 1;
  background: #101010;
}
